@import '../../../../scss/theme-bootstrap';

.whiteout {
  visibility: hidden;
}

.voice-search {
  position: absolute;
  width: 100%;
  top: 4.25rem;
  @if $search_modernized_design == true {
    display: none;
  }
  .fade-out {
    animation: fadeOut 0.35s ease-in-out forwards;
    z-index: -5;
  }
  .fade-in {
    animation: fadeIn 0.35s ease-in-out forwards;
    z-index: 2;
  }
  .search-form {
    &__mic {
      position: absolute;
      top: -3.6rem;
      #{$rdirection}: 12px;
      width: 2rem;
      height: 2rem;
      text-align: center;
      border: solid 1px transparent;
      border-radius: 50%;
      color: $color-dark-gray;
      .icon {
        &--microphone {
          height: 1.4rem;
          width: 0.9rem;
          margin-top: 10px;
        }
      }
    }
  }
  &__overlay {
    position: relative;
    background: $white;
    padding-top: 0.5rem;
    width: 100%;
    top: -4.3rem;
    &--searching {
      height: 100vh;
    }
    &--top,
    &--title {
      text-align: center;
      color: $color-grey-text;
    }
    &--top {
      position: absolute;
      top: 1.75rem;
      #{$ldirection}: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      font-size: 0.9rem;
      font-style: italic;
    }
    &--title {
      font-weight: bold;
      font-size: 1.5rem;
    }
    &--retrymessage {
      text-align: center;
      color: $color-grey-text;
      letter-spacing: 1px;
      font-weight: normal;
      font-size: 1rem;
    }
    &--transcription {
      padding: 0 0 0.5rem;
      margin: 1.25rem 1rem 0;
      color: $color-off-black;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
    }
    &--listening {
      .voice-search {
        &__overlay {
          &--transcription {
            border-bottom: 2px solid $color-dark-gray;
          }
        }
      }
    }
    &--examples {
      padding: 0 1rem 1rem;
    }
    &--suggestions {
      margin: 0.4rem 0 0;
      padding: 1rem 2rem;
      font-size: 0.85rem;
      letter-spacing: 1px;
      color: $color-dark-gray;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid $color-dark-gray;
    }
    &--close {
      position: absolute;
      #{$rdirection}: 5%;
      width: 1rem;
      height: 1rem;
      top: 0;
      .icon {
        &--close {
          height: 13px;
          width: 13px;
        }
      }
    }
  }
  @keyframes scaleIn {
    0% {
      transform: scale(1, 1);
      opacity: 0.75;
    }
    100% {
      transform: scale(3, 3);
      opacity: 0;
    }
  }
  @-webkit-keyframes scaleIn {
    0% {
      -webkit-transform: scale(1, 1);
      -webkit-opacity: 0.75;
    }
    100% {
      -webkit-transform: scale(3, 3);
      -webkit-opacity: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      -webkit-visibility: hidden;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-visibility: visible;
      -webkit-opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      -webkit-visibility: hidden;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-visibility: visible;
      -webkit-opacity: 1;
    }
  }
  &__animation {
    text-align: center;
    &--mic {
      width: 4rem;
      height: 4rem;
      position: relative;
      margin: 6.5em auto 3.5em;
      .icon {
        &--microphone {
          position: absolute;
          transform: translate(-50%, 0%);
          height: 4rem;
          width: 4rem;
          padding: 1.25rem;
          background: $white;
          border-radius: 50%;
        }
      }
    }
    &--circle {
      animation: scaleIn 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
      position: absolute;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      background-color: $color-grey-text;
      opacity: 0;
      &--one {
        animation-delay: -3s;
      }
      &--two {
        animation-delay: -2s;
      }
      &--three {
        animation-delay: -1s;
      }
      &--four {
        animation-delay: 0s;
      }
    }
    &--listening {
      .voice-search {
        &__animation--mic {
          display: block;
        }
      }
    }
    &--searching {
      .voice-search {
        &__animation--mic {
          display: none;
        }
      }
    }
  }
}

.search-results {
  &__header {
    .search-form {
      &__submit {
        #{$ldirection}: -70px;
        height: 30px;
      }
      &__field {
        text-align: center;
      }
      .icon--search {
        @if $search_modernized_design == false {
          #{$ldirection}: 5px;
          #{$rdirection}: auto;
        }
        transform: scaleX(-1);
      }
    }
    &--voice-search {
      height: auto;
      .voice-search {
        &__overlay {
          height: 100%;
        }
      }
    }
  }
}

.site-header {
  &__content {
    height: 70vh;
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    .search-form {
      &__fields {
        @if $search_modernized_design == false {
          padding-bottom: 7px;
          border-bottom: 2px solid $color-dark-gray;
        }
        .search-term {
          font-weight: 600;
        }
      }
    }
  }
}

.esearch-voice-search-form {
  position: relative;
}
